import React from 'react';

import { useClassnames } from '../../hooks/use-classnames';
import { IFooter } from '../../types/strapi/global';
import { isEn } from '../../utils/i18n';
import GridWrapper from '../grid-wrapper';
import Text from '../text';

import './index.css';

const rootClassName = 'footer-disclamer';

interface IFooterProps {
    data: IFooter,
    disclamerClassName?: string
}

const FooterDisclamer = ({ disclamerClassName, data }: IFooterProps) => {
    const cn = useClassnames();
    const {
        disclaimer,
        copyright,
        coreBusiness,
        documentsText
    } = data;


    return (
        <GridWrapper className={cn(rootClassName)}>
            <Text
                className={cn(`${rootClassName}__disclaimer`, disclamerClassName)}
                as="p"
                size={5}
                dangerouslySetInnerHTML={{ __html: disclaimer }}
            />

            <Text
                className={cn(`${rootClassName}__disclaimer`, disclamerClassName)}
                size={5}
            >
                <p dangerouslySetInnerHTML={{ __html: disclaimer }} />
                <p>
                    {coreBusiness}
                </p>
            </Text>

            <Text className={cn(`${rootClassName}__copyright`)} as="span" size={5}>{copyright}</Text>

            <Text className={cn(`${rootClassName}__legal-info`)} size={5}>
                {
                    documentsText && (
                        <a
                            className={cn(`${rootClassName}__documents`)}
                            href="/documents"
                        >
                            {documentsText}
                        </a>
                    )
                }
            </Text>
        </GridWrapper>
    );
};

export default FooterDisclamer;
