import React, { useEffect, useRef } from 'react';

import { useClassnames } from '../../../../hooks/use-classnames';
import { useIsClient } from '../../../../utils/hooks/useIsClient';
import { ISourceSizes } from '../../../../utils/hooks/useSourceByScreen';
import HeadingBannerExtended from '../../../HeadingBannerExtended';

import transportDesktopNormalImgSrc from './assets/desktopNormal/fleet-banner-preview.jpeg';
import flipDesktopNormalVideoSrc from './assets/desktopNormal/flip.mp4';
import selfDrivingCarDesktopNormalVideoSrc from './assets/desktopNormal/self-driving-car.mp4';
import transportDesktopSmallImgSrc from './assets/desktopSmall/fleet-banner-preview.jpeg';
import flipDesktopSmallVideoSrc from './assets/desktopSmall/flip.mp4';
import selfDrivingCarDesktopSmallVideoSrc from './assets/desktopSmall/self-driving-car.mp4';
import transportMobileImgSrc from './assets/mobile/fleet-banner-preview.jpeg';
import flipMobileVideoSrc from './assets/mobile/flip.mp4';
import selfDrivingCarMobileVideoSrc from './assets/mobile/self-driving-car.mp4';
import transportTabletImgSrc from './assets/tablet/fleet-banner-preview.jpeg';
import flipTabletVideoSrc from './assets/tablet/flip.mp4';
import selfDrivingCarTabletVideoSrc from './assets/tablet/self-driving-car.mp4';
import selfDrivingTruckDesktopNormalVideoSrc from './assets/desktopNormal/self-driving-truck.mp4'
import selfDrivingTruckDesktopSmallVideoSrc from './assets/desktopSmall/self-driving-truck.mp4'
import selfDrivingTruckTabletVideoSrc from './assets/desktopSmall/self-driving-truck.mp4'
import selfDrivingTruckMobileVideoSrc from './assets/desktopSmall/self-driving-truck.mp4'

import { FleetBannerVideo, IFleetBannerVideoSrc } from './FleetBannerVideo';


import './index.css';

export enum EFleetBannerVideoType {
    flip = 'flip',
    selfDrivingCar = 'selfDrivingCar',
    selfDrivingTruck = 'selfDrivingTruck'
}

const videoSets: Array<{id: string, video: ISourceSizes<IFleetBannerVideoSrc>}> = [
    {
        id   : EFleetBannerVideoType.selfDrivingTruck,
        video: {
            desktopNormal: { src: selfDrivingTruckDesktopNormalVideoSrc, type: 'mp4' },
            desktopSmall : { src: selfDrivingTruckDesktopSmallVideoSrc, type: 'mp4' },
            tablet       : { src: selfDrivingTruckTabletVideoSrc, type: 'mp4' },
            mobile       : { src: selfDrivingTruckMobileVideoSrc, type: 'mp4' }
        }
    },
    {
        id   : EFleetBannerVideoType.selfDrivingCar,
        video: {
            desktopNormal: { src: selfDrivingCarDesktopNormalVideoSrc, type: 'mp4' },
            desktopSmall : { src: selfDrivingCarDesktopSmallVideoSrc, type: 'mp4' },
            tablet       : { src: selfDrivingCarTabletVideoSrc, type: 'mp4' },
            mobile       : { src: selfDrivingCarMobileVideoSrc, type: 'mp4' }
        }
    },
    {
        id   : EFleetBannerVideoType.flip,
        video: {
            desktopNormal: { src: flipDesktopNormalVideoSrc, type: 'mp4' },
            desktopSmall : { src: flipDesktopSmallVideoSrc, type: 'mp4' },
            tablet       : { src: flipTabletVideoSrc, type: 'mp4' },
            mobile       : { src: flipMobileVideoSrc, type: 'mp4' }
        }
    }
];

interface IProps {
    className?: string,
    currentVideoId: string | null
}


const FleetBanner: React.FC<IProps> = ({ className, currentVideoId }) => {
    const cssBlock = 'fleet-banner';
    const cn = useClassnames();
    const isClient = useIsClient();

    const playerRefs = useRef<Array<{id: string, ref: HTMLVideoElement}>>([]);

    useEffect(() => {
        playerRefs.current.forEach((player) => {
            if(player.id === currentVideoId) {
                void player.ref.play();
            } else {
                player.ref.pause();
            }
        });
    }, [currentVideoId, isClient]);


    return (
        <HeadingBannerExtended
            title="Флот беспилотных автомобилей"
            description="Беспилотная технология совместима с&nbsp;разными видами транспорта. Флот SberAutoTech — это почти 200 легковых и грузовых автомобилей."
            className={cn(cssBlock, className)}
            titleSectionClassName={cn(`${cssBlock}__title-section`)}
            titleClassName={cn(`${cssBlock}__title`)}
            descriptionSectionClassName={cn(`${cssBlock}__description-section`)}
            descriptionClassName={cn(`${cssBlock}__description`)}
            image={{
                desktopNormal: transportDesktopNormalImgSrc,
                desktopSmall : transportDesktopSmallImgSrc,
                tablet       : transportTabletImgSrc,
                mobile       : transportMobileImgSrc
            }}
        >
            {isClient && videoSets.map((videoSet) => (
                <FleetBannerVideo
                    className={cn(`${cssBlock}__video`, { [`${cssBlock}__video_hidden`]: videoSet.id !== currentVideoId })}
                    videoSizes={videoSet.video}
                    key={`${videoSet.id}_${isClient}`}
                    setRef={(ref) => ref && playerRefs.current.push({ id: videoSet.id, ref })}
                />
            ))}
        </HeadingBannerExtended>
    );
};

export default FleetBanner;
