import React from 'react';

import { useClassnames } from '../../hooks/use-classnames';
import GridWrapper from '../grid-wrapper';
import { IPictureVariants, Picture } from '../Picture';
import Text from '../text';

import './index.css';
import {isRu} from "../../utils/locale";

export interface IHeadingBannerExtendedProps {
    title: string,
    description: string,
    className?: string,
    titleSectionClassName?: string,
    titleClassName?: string,
    descriptionSectionClassName?: string,
    descriptionClassName?: string,
    image?: IPictureVariants
}


const HeadingBannerExtended: React.FC<IHeadingBannerExtendedProps> = ({ title, description, className, titleClassName, descriptionClassName, titleSectionClassName, descriptionSectionClassName, children, ...restProps }) => {
    const cn = useClassnames();

    const cssBlock = 'heading-banner-extended';

    return (
        <div className={cn(cssBlock, className)}>
            <GridWrapper className={cn(`${cssBlock}__title-section`, titleSectionClassName)}>
                <div className={cn(`${cssBlock}__background`)}>
                    {restProps.image && <Picture image={restProps.image} alt={title} />}
                    {children}
                </div>
                <h1 className={cn(`${cssBlock}__title`, titleClassName)}>
                    {title}
                </h1>
            </GridWrapper>

            <GridWrapper className={cn(`${cssBlock}__description-section`, descriptionSectionClassName)}>
                <Text
                    className={cn(`${cssBlock}__description`, descriptionClassName)}
                    size={2}
                    dangerouslySetInnerHTML={{ __html: description}}
                />
            </GridWrapper>
        </div>
    );
};

export default HeadingBannerExtended;


