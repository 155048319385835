import React from 'react';

import { useClassnames } from '../../hooks/use-classnames';
import LinkCard, { ILinkCardData } from '../LinkCard';
import style from '../LinkCard/index.css';
import { Picture } from '../Picture';

import flipBelowDesktopImg from './assets/belowDesktop/flip.png';
import sdtBelowDesktopImg from './assets/belowDesktop/sdt.png'
import sidBelowDesktopImg from './assets/belowDesktop/sid.png';
import flipDesktopNormaImg from './assets/desktopNormal/flip.png';
import sidDesktopNormalImg from './assets/desktopNormal/sid.png';
import sdtDesktopNormalImg from './assets/desktopNormal/sdt.png';
import flipDesktopSmallImg from './assets/desktopSmall/flip.png';
import sidDesktopSmallImg from './assets/desktopSmall/sid.png';
import sdtDesktopSmallImg from './assets/desktopSmall/sdt.png';

import './index.css';


export enum ETransportTypeCardType {
    flip = 'FLIP',
    selfDrivingCar = 'SelfDrivingCar',
    selfDrivingTruck = 'SelfDrivingTruck'
}


const cardTypesData: {[type in ETransportTypeCardType]: ILinkCardData} = {
    [ETransportTypeCardType.selfDrivingTruck]: {
        image: {
            desktopNormal: sdtDesktopNormalImg,
            desktopSmall : sdtDesktopSmallImg,
            tablet       : sdtBelowDesktopImg,
            mobile       : sdtBelowDesktopImg,
            alt          : 'Изображение грузового беспилотного автомобиля СберАвтотех'
        },
        title      : 'Грузовые\r\nавтомобили',
        description: 'Новые сценарии грузоперевозок позволяют иначе организовать процессы, а система управления флотом делает логистику более эффективной',
        href       : '/self-driving-truck'
    },
    [ETransportTypeCardType.selfDrivingCar]: {
        image: {
            desktopNormal: sidDesktopNormalImg,
            desktopSmall : sidDesktopSmallImg,
            tablet       : sidBelowDesktopImg,
            mobile       : sidBelowDesktopImg,
            alt          : 'Изображение легкового беспилотного автомобиля СберАвтотех'
        },
        title      : 'Легковые\r\nавтомобили',
        description: 'Серийный автомобиль может стать беспилотным&#160;&#8212; оборудование устанавливается либо на&#160;производстве, либо на&#160;специализированной станции',
        href       : '/self-driving-car'
    },
    [ETransportTypeCardType.flip]: {
        image: {
            desktopNormal: flipDesktopNormaImg,
            desktopSmall : flipDesktopSmallImg,
            tablet       : flipBelowDesktopImg,
            mobile       : flipBelowDesktopImg,
            alt          : 'Изображение пассажирского беспилотного автомобиля СберАвтотех'
        },
        title      : 'ФЛИП',
        description: 'Создан исключительно для автономного вождения, в&#160;нем нет руля, панели управления и&#160;даже водительского кресла',
        href       : '/flip'
    }
};

interface ITranportTypeCardProps {
    className?: string,
    description?: string,
    type: ETransportTypeCardType,
    onMouseEnter?: React.MouseEventHandler
}

const cssBlock = 'transport-type-card';

const TransportTypeCard: React.FC<ITranportTypeCardProps> = ({ type, description, className, onMouseEnter }) => {
    const cn = useClassnames(style);

    const cardData = cardTypesData[type];
    const cardImage = cardData.image;

    return (
        <LinkCard
            className={cn(cssBlock, className)}
            {...cardData}
            description={description || cardData.description}
            onMouseEnter={onMouseEnter}
        >
            {(cardImage?.desktopNormal || cardImage?.desktopSmall || cardImage?.tablet || cardImage?.mobile) && (
                <Picture
                    className={cn(`${cssBlock}__picture`)}
                    image={cardImage}
                    alt={cardImage.alt || ''}
                />
            )}
        </LinkCard>

    );
};

type TConcreteTransportTypeCardProps = Omit<ITranportTypeCardProps, 'type'>;

export const FlipCard = (props: TConcreteTransportTypeCardProps) => <TransportTypeCard type={ETransportTypeCardType.flip} {...props} />;
export const SelfDrivingCarCard = (props: TConcreteTransportTypeCardProps) => <TransportTypeCard type={ETransportTypeCardType.selfDrivingCar} {...props} />;
export const SelfDrivingTruckCard = (props: TConcreteTransportTypeCardProps) => <TransportTypeCard type={ETransportTypeCardType.selfDrivingTruck} {...props}/>
