import { GetServerDataReturn, navigate } from 'gatsby';
import React, { useEffect } from 'react';

import FleetPage from '../components/FleetPage';
import { GLOBAL_QUERY } from '../constants';
import { TPageProp } from '../types';
import { IGlobalServerRuntimeData } from '../types/strapi/global';

type TProp = TPageProp<IGlobalServerRuntimeData>;

const Fleet: React.FC<TProp> = ({ serverData }) => {
    const isHiddenPage = true;

    useEffect(() => {
        if(isHiddenPage) {
            navigate('/');
        }
    }, []);

    if(isHiddenPage) {
        return null;
    }

    return (
        <FleetPage data={serverData} />
    );
};

export default Fleet;

const query = `
  query Global ($locale: String) {
    ${GLOBAL_QUERY}
  }
`;

export async function getServerData(): Promise<GetServerDataReturn<IGlobalServerRuntimeData>> {
    try {
        const variables = JSON.stringify({
            locale: process.env.GATSBY_LOCALE_CODE
        });
        const response = await fetch(
            `${process.env.GATSBY_API_URL}/graphql?query=${query}&variables=${variables}`
        );

        const data: { data: IGlobalServerRuntimeData } = await response.json();

        return {
            props: {
                navPanel   : data.data.navPanel,
                global     : data.data.global,
                respondForm: data.data.respondForm,
                footer     : data.data.footer
            }
        };
    } catch(error) {
        return {
            status: 500
        };
    }
}
